import React, { useEffect } from "react";
import Container from "@src/components/Container";
import CurrentOpeningsSection from "@src/components/partials/newcareer/CurrentOpeningsSection";
import { Title, BodyText, Space, Button, Subheading, DetailText, Heading } from "@src/components/nessie-web";
import { ChevronDownIcon } from "@classdojo/web/nessie/icons";
import { graphql, useStaticQuery } from "gatsby";
import SEO from "../components/SEO";
import {
  SpaceBackground,
  GlobalSection,
  Hero,
  HeroTitle,
  SpaceHeroContent,
  dt_white,
  ScrollMessage,
  AnimationShadow,
  WorldContainer,
  BrandLoveSection,
  BrandLoveHeader,
  BrandLoveGrid,
  BrandLoveGridRow,
  SliderTrackOne,
  ImageSquareTile,
  TweetTile,
  TweetUser,
  TweetUserWrapper,
  TweetUserAvatar,
  SliderTrackTwo,
  SliderTrackThree,
  CultureSection,
  CultureText,
  CultureGrid,
  CultureItem,
  CultureItemIcon,
  CultureItemContent,
  dt_taro40,
  RolesSection,
  RolesContentWrapper,
  UpAwaySection,
  UpAwayContent,
  UpAwayImageWrapper,
  UpAwayText,
} from "../components/jobs.styles";

const Jobs = () => {
  const data = useStaticQuery(graphql`
    query {
      directus {
        page_careers {
          hero_title
          hero_text
          hero_button_text
          scroll_text
          globe_classdojo_coverage
          globe_classdojo_countries
          globe_classdojo_usa
          brand_love_title
          brand_love_text
          brand_love_images
          culture_tiles
          culture_title
          culture_text
          roles_title
          roles_text
          brand_love_tweets
          vision_mojo_image_url
          vision_mojo_image_alt
          vision_title
          vision_text
        }
      }
    }
  `);

  const {
    directus: {
      page_careers: {
        hero_title,
        hero_text,
        hero_button_text,
        scroll_text,
        globe_classdojo_coverage,
        globe_classdojo_countries,
        globe_classdojo_usa,
        brand_love_title,
        brand_love_text,
        brand_love_images,
        culture_tiles,
        culture_title,
        culture_text,
        roles_title,
        roles_text,
        brand_love_tweets,
        vision_mojo_image_url,
        vision_mojo_image_alt,
        vision_title,
        vision_text,
      },
    },
  } = data;

  useEffect(() => {
    const appendGlobeScript = () => {
      const globeScript = document.createElement("script");
      globeScript.setAttribute("src", `${window.location.origin}/globe.js`);
      globeScript.setAttribute("type", "text/javascript");
      document.querySelector("head").append(globeScript);
    };

    appendGlobeScript();

    document.querySelector("html").style.scrollBehavior = "smooth";
  }, []);

  return (
    <>
      <SEO title="page_titles.jobs" description="page_descriptions.jobs" />
      <SpaceBackground id="global-wrapper">
        <GlobalSection>
          <div>
            <Hero id="one">
              <div className="inner-content">
                <HeroTitle>{hero_title}</HeroTitle>
                <SpaceHeroContent>
                  <Space size="m" />
                  <BodyText color={dt_white}>{hero_text}</BodyText>
                  <Space size="m" />
                  <Button as="a" href="#open-roles">
                    {hero_button_text}
                  </Button>
                  <ScrollMessage>
                    {scroll_text}
                    <ChevronDownIcon color="white" />
                  </ScrollMessage>
                </SpaceHeroContent>
              </div>
            </Hero>
            <div id="globe">
              <AnimationShadow id="animation-shadow" />
              <WorldContainer className="world-container">
                <div className="world">
                  <div className="world-globe">
                    <div className="world-globe-pole"></div>
                    <div className="world-globe-doms-container"></div>
                    <div className="world-globe-halo"></div>
                  </div>
                </div>
              </WorldContainer>
            </div>
            <div id="two">
              <div className="inner-content">
                <Space size="l" />
                <BodyText color={dt_white}>{globe_classdojo_coverage}</BodyText>
                {/* <Space size="xs" /> */}
                <Title size={2} color={dt_white}>
                  {globe_classdojo_countries}
                </Title>
              </div>
            </div>
            <div id="three">
              <div className="inner-content">
                <Space size="l" />
                <BodyText color={dt_white}>{globe_classdojo_coverage}</BodyText>
                {/* <Space size="s" /> */}
                <Title size={2} color={dt_white}>
                  {globe_classdojo_usa}
                </Title>
              </div>
            </div>
          </div>
        </GlobalSection>

        <BrandLoveSection>
          <Container>
            <BrandLoveHeader>
              <Title size={2} color={dt_white}>
                {brand_love_title}
              </Title>
              <Space size="l" />
              <BodyText color={dt_white}>{brand_love_text}</BodyText>
            </BrandLoveHeader>
          </Container>
          <BrandLoveGrid>
            <BrandLoveGridRow>
              <SliderTrackOne>
                <div>
                  <ImageSquareTile src={brand_love_images[0].image_url} alt={brand_love_images[0].alt} />
                </div>

                <div>
                  <ImageSquareTile src={brand_love_images[1].image_url} alt={brand_love_images[1].alt} />
                </div>

                <div>
                  <ImageSquareTile src={brand_love_images[2].image_url} alt={brand_love_images[2].alt} />
                </div>

                <div>
                  <TweetTile width={2}>
                    <TweetUser className="tweet-user">
                      <TweetUserWrapper>
                        <TweetUserAvatar avatar={brand_love_tweets[0].avatar} />
                        <div>
                          <Subheading color={dt_white}>{brand_love_tweets[0].username}</Subheading>
                          <DetailText color={dt_white}>{brand_love_tweets[0].at}</DetailText>
                        </div>
                      </TweetUserWrapper>
                    </TweetUser>
                    {brand_love_tweets[0].text}
                  </TweetTile>
                </div>

                <div>
                  <ImageSquareTile src={brand_love_images[3].image_url} alt={brand_love_images[3].alt} />
                </div>

                <div>
                  <TweetTile width={1}>
                    <TweetUser className="tweet-user">
                      <TweetUserWrapper>
                        <TweetUserAvatar avatar={brand_love_tweets[1].avatar} />
                        <div>
                          <Subheading color={dt_white}>{brand_love_tweets[1].username}</Subheading>
                          <DetailText color={dt_white}>{brand_love_tweets[1].at}</DetailText>
                        </div>
                      </TweetUserWrapper>
                    </TweetUser>
                    {brand_love_tweets[1].text}
                  </TweetTile>
                </div>

                <div>
                  <ImageSquareTile src={brand_love_images[4].image_url} alt={brand_love_images[4].alt} />
                </div>

                <div>
                  <ImageSquareTile src={brand_love_images[0].image_url} alt={brand_love_images[0].alt} />
                </div>

                <div>
                  <ImageSquareTile src={brand_love_images[1].image_url} alt={brand_love_images[1].alt} />
                </div>

                <div>
                  <ImageSquareTile src={brand_love_images[2].image_url} alt={brand_love_images[2].alt} />
                </div>

                <div>
                  <TweetTile width={2}>
                    <TweetUser className="tweet-user">
                      <TweetUserWrapper>
                        <TweetUserAvatar avatar={brand_love_tweets[0].avatar} />
                        <div>
                          <Subheading color={dt_white}>{brand_love_tweets[0].username}</Subheading>
                          <DetailText color={dt_white}>{brand_love_tweets[0].at}</DetailText>
                        </div>
                      </TweetUserWrapper>
                    </TweetUser>
                    {brand_love_tweets[0].text}
                  </TweetTile>
                </div>

                <div>
                  <ImageSquareTile src={brand_love_images[3].image_url} alt={brand_love_images[3].alt} />
                </div>

                <div>
                  <TweetTile width={1}>
                    <TweetUser className="tweet-user">
                      <TweetUserWrapper>
                        <TweetUserAvatar avatar={brand_love_tweets[1].avatar} />
                        <div>
                          <Subheading color={dt_white}>{brand_love_tweets[1].username}</Subheading>
                          <DetailText color={dt_white}>{brand_love_tweets[1].at}</DetailText>
                        </div>
                      </TweetUserWrapper>
                    </TweetUser>
                    {brand_love_tweets[1].text}
                  </TweetTile>
                </div>

                <div>
                  <ImageSquareTile src={brand_love_images[4].image_url} alt={brand_love_images[4].alt} />
                </div>
              </SliderTrackOne>
            </BrandLoveGridRow>

            <BrandLoveGridRow>
              <SliderTrackTwo>
                <div>
                  <TweetTile width={2}>
                    <TweetUser className="tweet-user">
                      <TweetUserWrapper>
                        <TweetUserAvatar avatar={brand_love_tweets[0].avatar} />
                        <div>
                          <Subheading color={dt_white}>{brand_love_tweets[0].username}</Subheading>
                          <DetailText color={dt_white}>{brand_love_tweets[0].at}</DetailText>
                        </div>
                      </TweetUserWrapper>
                    </TweetUser>
                    {brand_love_tweets[0].text}
                  </TweetTile>
                </div>

                <div>
                  <ImageSquareTile src={brand_love_images[4].image_url} alt={brand_love_images[4].alt} />
                </div>

                <div>
                  <TweetTile width={1}>
                    <TweetUser className="tweet-user">
                      <TweetUserWrapper>
                        <TweetUserAvatar avatar={brand_love_tweets[2].avatar} />
                        <div>
                          <Subheading color={dt_white}>{brand_love_tweets[2].username}</Subheading>
                          <DetailText color={dt_white}>{brand_love_tweets[2].at}</DetailText>
                        </div>
                      </TweetUserWrapper>
                    </TweetUser>
                    {brand_love_tweets[2].text}
                  </TweetTile>
                </div>

                <div>
                  <ImageSquareTile src={brand_love_images[5].image_url} alt={brand_love_images[5].alt} />
                </div>

                <div>
                  <ImageSquareTile src={brand_love_images[6].image_url} alt={brand_love_images[6].alt} />
                </div>

                <div>
                  <TweetTile width={3}>
                    <TweetUser className="tweet-user">
                      <TweetUserWrapper>
                        <TweetUserAvatar avatar={brand_love_tweets[3].avatar} />
                        <div>
                          <Subheading color={dt_white}>{brand_love_tweets[3].username}</Subheading>
                          <DetailText color={dt_white}>{brand_love_tweets[3].at}</DetailText>
                        </div>
                      </TweetUserWrapper>
                    </TweetUser>
                    {brand_love_tweets[3].text}
                  </TweetTile>
                </div>

                <div>
                  <ImageSquareTile src={brand_love_images[7].image_url} alt={brand_love_images[7].alt} />
                </div>

                <div>
                  <TweetTile width={2}>
                    <TweetUser className="tweet-user">
                      <TweetUserWrapper>
                        <TweetUserAvatar avatar={brand_love_tweets[4].avatar} />
                        <div>
                          <Subheading color={dt_white}>{brand_love_tweets[4].username}</Subheading>
                          <DetailText color={dt_white}>{brand_love_tweets[4].at}</DetailText>
                        </div>
                      </TweetUserWrapper>
                    </TweetUser>
                    {brand_love_tweets[4].text}
                  </TweetTile>
                </div>

                <div>
                  <TweetTile width={2}>
                    <TweetUser className="tweet-user">
                      <TweetUserWrapper>
                        <TweetUserAvatar avatar={brand_love_tweets[0].avatar} />
                        <div>
                          <Subheading color={dt_white}>{brand_love_tweets[0].username}</Subheading>
                          <DetailText color={dt_white}>{brand_love_tweets[0].at}</DetailText>
                        </div>
                      </TweetUserWrapper>
                    </TweetUser>
                    {brand_love_tweets[0].text}
                  </TweetTile>
                </div>

                <div>
                  <ImageSquareTile src={brand_love_images[4].image_url} alt={brand_love_images[4].alt} />
                </div>

                <div>
                  <TweetTile width={1}>
                    <TweetUser className="tweet-user">
                      <TweetUserWrapper>
                        <TweetUserAvatar avatar={brand_love_tweets[2].avatar} />
                        <div>
                          <Subheading color={dt_white}>{brand_love_tweets[2].username}</Subheading>
                          <DetailText color={dt_white}>{brand_love_tweets[2].at}</DetailText>
                        </div>
                      </TweetUserWrapper>
                    </TweetUser>
                    {brand_love_tweets[2].text}
                  </TweetTile>
                </div>

                <div>
                  <ImageSquareTile src={brand_love_images[5].image_url} alt={brand_love_images[5].alt} />
                </div>

                <div>
                  <ImageSquareTile src={brand_love_images[6].image_url} alt={brand_love_images[6].alt} />
                </div>

                <div>
                  <TweetTile width={3}>
                    <TweetUser className="tweet-user">
                      <TweetUserWrapper>
                        <TweetUserAvatar avatar={brand_love_tweets[3].avatar} />
                        <div>
                          <Subheading color={dt_white}>{brand_love_tweets[3].username}</Subheading>
                          <DetailText color={dt_white}>{brand_love_tweets[3].at}</DetailText>
                        </div>
                      </TweetUserWrapper>
                    </TweetUser>
                    {brand_love_tweets[3].text}
                  </TweetTile>
                </div>

                <div>
                  <ImageSquareTile src={brand_love_images[7].image_url} alt={brand_love_images[7].alt} />
                </div>

                <div>
                  <TweetTile width={2}>
                    <TweetUser className="tweet-user">
                      <TweetUserWrapper>
                        <TweetUserAvatar avatar={brand_love_tweets[4].avatar} />
                        <div>
                          <Subheading color={dt_white}>{brand_love_tweets[4].username}</Subheading>
                          <DetailText color={dt_white}>{brand_love_tweets[4].at}</DetailText>
                        </div>
                      </TweetUserWrapper>
                    </TweetUser>
                    {brand_love_tweets[4].text}
                  </TweetTile>
                </div>
              </SliderTrackTwo>
            </BrandLoveGridRow>

            <BrandLoveGridRow>
              <SliderTrackThree>
                <div>
                  <ImageSquareTile src={brand_love_images[8].image_url} alt={brand_love_images[8].alt} />
                </div>

                <div>
                  <ImageSquareTile src={brand_love_images[9].image_url} alt={brand_love_images[9].alt} />
                </div>

                <div>
                  <TweetTile width={1}>
                    <TweetUser className="tweet-user">
                      <TweetUserWrapper>
                        <TweetUserAvatar avatar={brand_love_tweets[1].avatar} />
                        <div>
                          <Subheading color={dt_white}>{brand_love_tweets[1].username}</Subheading>
                          <DetailText color={dt_white}>{brand_love_tweets[1].at}</DetailText>
                        </div>
                      </TweetUserWrapper>
                    </TweetUser>
                    {brand_love_tweets[1].text}
                  </TweetTile>
                </div>

                <div>
                  <ImageSquareTile src={brand_love_images[12].image_url} alt={brand_love_images[12].alt} />
                </div>

                <div>
                  <TweetTile width={1}>
                    <TweetUser className="tweet-user">
                      <TweetUserWrapper>
                        <TweetUserAvatar avatar={brand_love_tweets[5].avatar} />
                        <div>
                          <Subheading color={dt_white}>{brand_love_tweets[5].username}</Subheading>
                          <DetailText color={dt_white}>{brand_love_tweets[5].at}</DetailText>
                        </div>
                      </TweetUserWrapper>
                    </TweetUser>
                    {brand_love_tweets[5].text}
                  </TweetTile>
                </div>

                <div>
                  <ImageSquareTile src={brand_love_images[10].image_url} alt={brand_love_images[10].alt} />
                </div>

                <div>
                  <ImageSquareTile src={brand_love_images[11].image_url} alt={brand_love_images[11].alt} />
                </div>

                <div>
                  <ImageSquareTile src={brand_love_images[0].image_url} alt={brand_love_images[0].alt} />
                </div>

                <div>
                  <ImageSquareTile src={brand_love_images[8].image_url} alt={brand_love_images[8].alt} />
                </div>

                <div>
                  <ImageSquareTile src={brand_love_images[9].image_url} alt={brand_love_images[9].alt} />
                </div>

                <div>
                  <TweetTile width={1}>
                    <TweetUser className="tweet-user">
                      <TweetUserWrapper>
                        <TweetUserAvatar avatar={brand_love_tweets[1].avatar} />
                        <div>
                          <Subheading color={dt_white}>{brand_love_tweets[1].username}</Subheading>
                          <DetailText color={dt_white}>{brand_love_tweets[1].at}</DetailText>
                        </div>
                      </TweetUserWrapper>
                    </TweetUser>
                    {brand_love_tweets[1].text}
                  </TweetTile>
                </div>

                <div>
                  <ImageSquareTile src={brand_love_images[12].image_url} alt={brand_love_images[12].alt} />
                </div>

                <div>
                  <TweetTile width={1}>
                    <TweetUser className="tweet-user">
                      <TweetUserWrapper>
                        <TweetUserAvatar avatar={brand_love_tweets[5].avatar} />
                        <div>
                          <Subheading color={dt_white}>{brand_love_tweets[5].username}</Subheading>
                          <DetailText color={dt_white}>{brand_love_tweets[5].at}</DetailText>
                        </div>
                      </TweetUserWrapper>
                    </TweetUser>
                    {brand_love_tweets[5].text}
                  </TweetTile>
                </div>

                <div>
                  <ImageSquareTile src={brand_love_images[10].image_url} alt={brand_love_images[10].alt} />
                </div>

                <div>
                  <ImageSquareTile src={brand_love_images[11].image_url} alt={brand_love_images[11].alt} />
                </div>

                <div>
                  <ImageSquareTile src={brand_love_images[0].image_url} alt={brand_love_images[0].alt} />
                </div>
              </SliderTrackThree>
            </BrandLoveGridRow>
          </BrandLoveGrid>
        </BrandLoveSection>

        <CultureSection>
          <Container>
            <Title size={2} color={dt_white}>
              {culture_title}
            </Title>
            <Space size="l" />
            <CultureText>
              <BodyText color={dt_white}>{culture_text}</BodyText>
            </CultureText>
            <Space size="xxl" />

            <CultureGrid>
              {culture_tiles.map((tile, index) => (
                <CultureItem key={index}>
                  <CultureItemIcon src={tile.icon_url} />
                  <CultureItemContent>
                    <Heading color={dt_white}>{tile.title}</Heading>
                    <BodyText color={dt_taro40}>{tile.text}</BodyText>
                  </CultureItemContent>
                </CultureItem>
              ))}
            </CultureGrid>
          </Container>
        </CultureSection>

        <RolesSection>
          <Container>
            <RolesContentWrapper id="open-roles">
              <Space size="l" />
              <Title color={dt_white} size={2}>
                {roles_title}
              </Title>
              <Space size="l" />
              <BodyText color={dt_white}>{roles_text}</BodyText>
              <Space size="xxl" />
              <CurrentOpeningsSection />
              <Space size="xxl" />
            </RolesContentWrapper>
          </Container>
        </RolesSection>

        <UpAwaySection>
          <Container>
            <UpAwayContent>
              <UpAwayImageWrapper>
                <img src={vision_mojo_image_url} alt={vision_mojo_image_alt} />
              </UpAwayImageWrapper>
              <UpAwayText>
                {vision_title} <br />
                {vision_text}
              </UpAwayText>
            </UpAwayContent>
          </Container>
        </UpAwaySection>
      </SpaceBackground>
    </>
  );
};

export default Jobs;
